import React from "react";
import { Table } from "react-bootstrap";

import "../stylesheets/About.css";

export const About: React.FC = () => {
  return (
    <div className="about">
      <br />
      <h1>ABOUT ME:</h1>
      <br />
      <p>
        Hi. I'm Kacp3r. I got into programming around late July of 2020, and
        have been doing web development since mid November of 2020.
      </p>
      <br />
      <p>
        I enjoy everything that has to do with computers, whether that be
        browsing the internet, playing games, or developing programs and
        websites for the world wide web.
      </p>
      <br />
      <h1>SKILLS:</h1>
      <br />
      <div className="techList">
        <h2>Programming Languages</h2>
        <Table borderless variant="dark" className="techTable">
          <tbody>
            <tr>
              <td>▹HTML/CSS</td>
              <td>▹Python</td>
            </tr>
            <tr>
              <td>▹TypeScript</td>
              <td>▹JavaScript</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <h2>Frameworks</h2>
        <Table borderless variant="dark" className="techTable">
          <tbody>
            <tr>
              <td>▹React</td>
              <td>▹Node.js</td>
            </tr>
            <tr>
              <td>▹Electron</td>
              <td>▹Discord.js</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <h2>Tools/Platforms</h2>
        <Table borderless variant="dark" className="techTable">
          <tbody>
            <tr>
              <td>▹Git</td>
              <td>▹GitHub</td>
            </tr>
            <tr>
              <td>▹Heroku</td>
              <td>▹Netlify</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <br />
    </div>
  );
};
