import React from "react";
import "../stylesheets/NotFound.css";

export const NotFound: React.FC = () => {
  return (
    <div className="notFound">
      <h1>
        {"{"}OUCH{"}"}
      </h1>
      <br />
      <h2>
        The page you were looking for is either down, or no longer exists.
      </h2>
    </div>
  );
};
