import React from "react";
import {
  External,
  Folder,
  GitHub,
  Linkedin,
  Twitter,
  Location,
  RightArrow,
  Clock,
} from "./icons/export";

type Props = {
  name: string;
};

const Icon: React.FC<Props> = ({ name }) => {
  switch (name) {
    case "Folder":
      return <Folder />;
    case "GitHub":
      return <GitHub />;
    case "Linkedin":
      return <Linkedin />;
    case "Twitter":
      return <Twitter />;
    case "Location":
      return <Location />;
    case "RightArrow":
      return <RightArrow />;
    case "Clock":
      return <Clock />;
    default:
      return <External />;
  }
};

export default Icon;
