import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Projects } from "./pages/Projects";
import { RickRoll } from "./components/RickRoll";
import { NotFound } from "./pages/NotFound";
import { Layout } from "./components/Layout";
import { NavigationBar } from "./components/NavigationBar";
import "./stylesheets/App.css";

const App: React.FC = () => {
  return (
    <>
      <NavigationBar />
      <br />
      <br />
      <Layout>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/projects" component={Projects} />
            <Route path="/admin" component={RickRoll} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Layout>
    </>
  );
};

export default App;
