import React, { useState } from "react";
import Typed from "react-typed";
import Icon from "../components/Icon";
import { Button } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import ParticlesBg from "particles-bg";
import "../stylesheets/Home.css";

export const Home: React.FC = () => {
  let initialTime = moment.tz(moment(), "America/New_York").format("LTS");
  let [timeDisplay, setTimeDisplay] = useState(initialTime);
  setInterval(() => {
    let time = moment.tz(moment(), "America/New_York").format("LTS");
    setTimeDisplay(time);
  }, 1000);
  return (
    <div className="home">
      <p>Hi there, I'm</p>
      <h1>KACP3R</h1>
      <h2>
        {`{ `}
        <Typed
          strings={[
            "Front End Developer",
            "TypeScript Developer",
            "Tech Enthusiast",
          ]}
          typeSpeed={50}
          backDelay={1300}
          backSpeed={30}
          loop
        />
        {`}`}
      </h2>
      <br />
      <p>
        <Icon name={"Location"} />
        New York, NY
      </p>
      <p>
        <Icon name={"Clock"} />
        {timeDisplay} EST
      </p>
      <br />
      <h2>LINKS</h2>
      <div className="linkWrapper">
        <Button
          variant="outline-primary"
          className="linkBtn"
          href="https://github.com/kacp3rrr"
          target="_blank"
        >
          GITHUB <Icon name={"GitHub"} />
        </Button>{" "}
        <Button
          variant="outline-primary"
          className="linkBtn"
          href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=kacperfilms33@gmail.com"
          target="_blank"
        >
          GMAIL <Icon name={"Folder"} />
        </Button>
      </div>
      <ParticlesBg color="#173a6d" type="cobweb" bg={true} />
    </div>
  );
};
