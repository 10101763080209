import React from "react";
import { Route } from "react-router-dom";

export const RickRoll = () => {
  return (
    <Route
      render={() => {
        window.location.replace("https://youtube.com///////////////logout//////////");
        return null;
      }}
    />
  );
};
