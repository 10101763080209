import React from "react";
import { Card, CardGroup, Button } from "react-bootstrap";
import Icon from "../components/Icon";
import "../stylesheets/Projects.css";

export const Projects: React.FC = () => {
  return (
    <div className="projects">
      <br />
      <h1>PROJECTS:</h1>
      <CardGroup>
        <Card className="projectCard">
          <Card.Body>
            <Card.Title>react-decimal-age</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              React, TypeScript
            </Card.Subtitle>
            <Card.Text>
              Find the age of anything to a precise and live-updating decimal.
            </Card.Text>
            <Button
              variant="outline-primary"
              className="linkBtn"
              href="https://github.com/kacp3rrr/react-decimal-age"
              target="_blank"
            >
              GITHUB <Icon name={"GitHub"} />
            </Button>{" "}
            <Button
              variant="outline-primary"
              className="linkBtn"
              href="https://focused-brattain-e6846b.netlify.app/"
              target="_blank"
            >
              WEBSITE <Icon name={""} />
            </Button>
          </Card.Body>
        </Card>
        <Card className="projectCard">
          <Card.Body>
            <Card.Title>react-christmas-card</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              React, TypeScript
            </Card.Subtitle>
            <Card.Text>
              Generate a wonderful and animated christmas card to send to your
              friends.
            </Card.Text>
            <Button
              variant="outline-primary"
              className="linkBtn"
              href="https://github.com/kacp3rrr/react-christmas-card"
              target="_blank"
            >
              GITHUB <Icon name={"GitHub"} />
            </Button>{" "}
            <Button
              variant="outline-primary"
              className="linkBtn"
              href="https://christmascardgenerator.netlify.app/"
              target="_blank"
            >
              WEBSITE <Icon name={""} />
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>
      <br />
      <p>
        --- ALL OPEN SOURCE PROJECTS NOT INCLUDED HERE ARE ON MY{" "}
        <a href="https://github.com/kacp3rrr">GITHUB</a> ---
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
